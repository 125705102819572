body {
  box-sizing: border-box;
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;
  font-size: 16px;
  margin: 0 auto;
  max-width: 680px;
}

.container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
  padding: 20px 20px 60px;
}

.text-center {
  text-align: center;
}

.spacer {
  height: 16px;
  width: 100%;
}
